import { useEffect } from "react";
import "../chapterControalr.css";
import { volumeHandler } from "../../utilities";
const Volume = () => {
    useEffect(() => {
        volumeHandler();
    }, []);
    return (
        <div className="flex volume-controlar justify-between items-center w-[70%] sm:w-[100%] md:w-[100%] lg:w-[100%] m-auto">
            <i className="fa-solid me-5 volume-icone text-white fa-volume-high"></i>
            <div className="thumb-controal w-full relative">
                <input type="range" id="thumb-volume" className="w-full opacity-0 p-[13px] relative cursor-pointer z-50" name="volume" defaultValue={0} steps={0} min={0} />
                <span className="thumb-ui-full-width"></span>
                <span className="thumb-ui volume-thumb"></span>
                <span className="thumb-cricle volume-cricle"></span>
            </div>
        </div>
    );
}

export default Volume;