import { Fragment } from "react";

const ChapterVerses = ({ getCurrentChapter }) => {
    return (
        <Fragment>
            <p className="text-[20px] mb-7 font-bold  text-center">ايات سوره ({getCurrentChapter.name_arabic})</p>
            <p className='w-full text-[#999] leading-[2rem] p-3 mb-[200px]' dangerouslySetInnerHTML={{ __html: getCurrentChapter.words.replaceAll("مسافه", "<br>") }} id="verses">

            </p>
        </Fragment>
    )
}

export default ChapterVerses;