import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
    const getData = useSelector(store => store.quranData);
    const [chapter, setChapter] = useState('');

    const handelSearch = (value) => {
        const getChapter = getData.filter(chapter => chapter.name_arabic.includes(value) || chapter.name_complex.includes(value) || chapter.name_simple.includes(value) || chapter.translated_name.name.includes(value));
        return setChapter(getChapter[0]);
    }
    return (
        <div className="container grid grid-cols-12 pt-3 pb-3">
            <Link to={'/'} className='text-[30px] col-span-2 flex font-extrabold'>
                <img src={require("./logo.png")} width={100} />
            </Link>
            <div className="flex items-center justify-end p-2 col-span-10">
                <Link to={`${chapter && chapter !== '' ? `${chapter.id}?${chapter.name_arabic}` : "/notFound"}`} className="me-3 bg-[#333638] p-2 rounded-[10px] text-white text-[11px] sm:text-[15px] md:text-[15px] lg:text-[15px]">Search</Link>
                <input type="search" placeholder="ابحث عن السوره" onChange={(e) => handelSearch(e.target.value)} className="p-2 outline-none text-center font-semibold rounded-[10px]  w-96  bg-transparent border-2 border-[#4a4a4a]" />
            </div>
        </div>
    )
}

export default Header;