import { useDispatch, useSelector } from "react-redux";
import ChaptersOfQuran from "./Components/ChaptersOfQuran/ChaptersOfQuran";
import Header from "./Components/Header/Header";
import { useEffect } from "react";
import { fetchData } from "./store/sliecs/quranSlice";
import FixedButton from "./Components/FixedButton/FixedButton";
import { Route, Routes } from "react-router-dom";
import CurrentChapter from "./Components/CurrentChapter/CurrentChapter";
import NotFound from "./Components/NotFound/NotFound";
function App() {
  const getData = useSelector((store) => store.quranData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, []);
  return (
    <div className="App">
      <FixedButton />
      <Header />
      <Routes>
        <Route path="/" element={<ChaptersOfQuran />} />
        <Route path="/:chapterId" element={<CurrentChapter />} />
        <Route path="/notFound" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
