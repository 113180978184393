import { Fragment } from "react";
import SelectBox from "../../SelectBox/SelectBox";

const ChapterHeader = ({ getCurrentChapter, filterOptions, setFilterOptions }) => {
    let readers = getCurrentChapter && getCurrentChapter['audio_files'];
    return (
        <Fragment>
            <h1 className="text-[30px] mb-3 font-bold p-3">{getCurrentChapter.name_arabic} ({getCurrentChapter.name_complex})</h1>
            <div className="flex mb-7 font-extrabold p-3">
                <p className={`me-8 cursor-pointer p-2 pb-2 pe-3 ${filterOptions ? "active-border" : ""}`} onClick={() => setFilterOptions(true)}>الايات</p>
                <p className={`cursor-pointer p-2 pb-3 ${!filterOptions ? "active-border" : ""}`} onClick={() => setFilterOptions(false)}>معلومات عن السوره</p>
            </div>
            <div className="w-[500px] max-w-[100%] mb-5 p-2">
                <h2 className="text-[30px] mb-3 font-bold p-3">يمكنك اختيار العديد من الاصوات </h2>
                <SelectBox readers={readers} />
            </div>
        </Fragment>
    )
}

export default ChapterHeader;