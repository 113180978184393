import { createSlice } from "@reduxjs/toolkit";

const getReader = createSlice({
    name: "getReader",
    initialState: {},
    reducers: {
        setReader: (state, action) => {
            return state = action.payload
        }
    }
});

export default getReader.reducer;
export const { setReader } = getReader.actions;