import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchData } from "../../store/sliecs/quranSlice";
import { useEffect } from "react";

const NotFound = () => {
    const getData = useSelector((store) => store.quranData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchData());
    }, []);
    const renderData = getData && getData.map(data => {
        return (
            <div key={data.id} className='border-2 border-[#4a4a4a] col-span-12 sm:col-span-6 md:col-span-4  lg:col-span-3 p-3 pt-2 pb-2 mb-3 text-center text-[15px] font-bold rounded-[10px]'>
                <Link to={`/${data.id}?${data.name_arabic}`}><h1 className='cursor-pointer'>{data.name_arabic} ({data.name_complex})</h1></Link>
            </div>
        )
    });
    return (
        <div className="mt-[5rem]">
            <div className="container">
                <h1 className='font-extrabold text-center text-[25px] underline mb-5 p-2'>لا توجد سوره بهذه الاسم برجاء التأكد من اسم السوره</h1>
                <h3 className="font-extrabold text-[25px] mb-5 p-2">أسماء السور :</h3>
                <div className='grid grid-cols-12 gap-3 p-2'>
                    {renderData}
                </div>
            </div>
        </div>
    )
}

export default NotFound;