import { useEffect, useState } from "react";

const FixedButton = () => {
    const [toggleShowButton, setToggleShowButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 600) {
                setToggleShowButton(true);
            } else {
                setToggleShowButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <button className={`p-1 bg-[#4a4a4a] rounded-[5px] w-[80px] fixed bottom-[10rem] sm:bottom-3 md:bottom-3 lg:bottom-3 z-30 right-3 transition-all ${toggleShowButton ? "active-scale" : ""}`} style={{ transform: "scale(0)" }} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>Up</button>
    )
}

export default FixedButton;