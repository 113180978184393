import { useEffect } from "react";
import "../chapterControalr.css";
import { control_thumb, control_thumb_audio, timeUpdate } from "../../utilities";

const Thumb = () => {
    useEffect(() => {
        timeUpdate();
        control_thumb_audio();
        control_thumb();
    }, []);

    return (
        <div className="thumb-wrapper flex justify-between items-center col-span-12 sm:col-span-7 md:col-span-7 lg:col-span-8">
            <time className="start-time text-[13px]" dateTime="start">00:00</time>
            <div className="thumb-controal w-full relative">
                <input type="range" id="thumb" className="w-full opacity-0 p-[13px] relative cursor-pointer z-50" name="volume" defaultValue={0} step={0} min={0} />
                <span className="thumb-ui-full-width" htmlFor="thumb" ></span>
                <span id="seek" className="thumb-ui"></span>
                <span id="seek_dot" className="thumb-cricle"></span>
            </div>
            <time className="end-time" dateTime="end">00:00</time>
        </div>
    )
}

export default Thumb;