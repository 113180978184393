import { Fragment } from "react";

const ChapterInfo = ({ getCurrentChapter }) => {
    return (
        <Fragment>
            <p className="text-[20px] mb-7 font-bold text-center">معلومات عن سوره ({getCurrentChapter.name_arabic})</p>
            <p className="mb-5 text-[15px]">الاسم بالانجليزيه:  <span className="ms-2">{getCurrentChapter.name_simple}</span></p>
            <p className="mb-5 text-[15px]">تبدء من صفحه: {getCurrentChapter.pages[0]}</p>
            <p className="mb-5 text-[15px]">تنتهي الي صفحه: {getCurrentChapter.pages[1]}</p>
            <p className="mb-5 text-[15px]"> ترتيب النزول : {getCurrentChapter.revelation_order}</p>
            <p className="mb-5 text-[15px]">مكان النزول : {getCurrentChapter.revelation_place}</p>
            <p className="mb-5 text-[15px]">عدد الايات : {getCurrentChapter.verses_count}</p>
            <p className="mb-5 text-[15px]">هل هذه السوره تبدء بالبسمله : {getCurrentChapter.bismillah_pre ? "نعم تبدء بالبسمله" : "لا ليس تبدء بالبسمله"}</p>
        </Fragment>
    )
}

export default ChapterInfo;