import { Fragment } from "react";
import { audio } from "../../utilities";

const EffectIcons = () => {
    return (
        <Fragment>
            <div className="item-controlar">
                <i className="fa-solid fa-play thumb-icone text-white text-lg cursor-pointer" onClick={() => {
                    if (audio.paused) audio.play();
                    else
                        audio.pause();
                }}></i>
            </div>
        </Fragment>
    );
}

export default EffectIcons;