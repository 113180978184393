import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ChapterControalr from "../ChapterControalr/ChapterControalr";
import { useEffect, useState } from "react";
import NotFound from "../NotFound/NotFound";
import { fetchData } from "../../store/sliecs/quranSlice";
import ChapterInfo from "./ChapterInfo/ChapterInfo";
import ChapterVerses from "./ChapterVerses/ChapterVerses";
import ChapterHeader from "./ChapterHeader/ChapterHeader";
const CurrentChapter = () => {
    const [filterOptions, setFilterOptions] = useState(true);
    const dispatch = useDispatch();
    const prams = useParams().chapterId;
    const data = useSelector(store => store.quranData);
    const getCurrentChapter = data.filter(chapter => chapter.id == prams)[0];
    useEffect(() => {
        dispatch(fetchData());
    }, []);

    return (
        getCurrentChapter ? (
            <div className="mt-9 mb-[80px] ">
                <div className="container">
                    <ChapterHeader getCurrentChapter={getCurrentChapter} filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
                    {!filterOptions && <ChapterInfo getCurrentChapter={getCurrentChapter} />}
                    {filterOptions && <ChapterVerses getCurrentChapter={getCurrentChapter} />}
                </div>
                <ChapterControalr />
            </div>
        )
            : <NotFound />
    )
}

export default CurrentChapter;