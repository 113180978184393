// main audio
export const audio = new Audio("https://download.quranicaudio.com/qdc/mishari_al_afasy/murattal/1.mp3");
const audioEnded = () => {
    audio.addEventListener("ended", () => {
        Array.from(document.querySelectorAll(".fa-pause")).forEach(icon => {
            icon.classList.remove("fa-pause");
            icon.classList.add("fa-play");
        });
    });
};
export const timeUpdate = () => {
    const start_Time = document.querySelector(".start-time");
    const end_Time = document.querySelector(".end-time");
    const thumb_icone = document.querySelector(".thumb-icone");

    audio.addEventListener("timeupdate", () => {
        thumb_icone.classList.remove("fa-play");
        thumb_icone.classList.add("fa-pause");

        let audio_duration = audio.duration;
        let audio_current_time = audio.currentTime;

        let start_minutes = Math.floor(audio_current_time / 60);
        let start_seconds = Math.floor(audio_current_time % 60);
        let start_hours = 0;

        if (start_minutes >= 60) {
            start_hours = Math.floor(start_minutes / 60);
            start_minutes = start_minutes % 60;
        }

        if (start_minutes < 10) start_minutes = `0${start_minutes}`;
        if (start_seconds < 10) start_seconds = `0${start_seconds}`;

        start_Time.innerHTML = `${start_hours}:${start_minutes}:${start_seconds}`;

        let end_minutes = Math.floor((audio_duration - audio_current_time) / 60);
        let end_seconds = Math.floor((audio_duration - audio_current_time) % 60);
        let end_hours = 0;

        if (end_minutes >= 60) {
            end_hours = Math.floor(end_minutes / 60);
            end_minutes = end_minutes % 60;
        }

        if (end_seconds < 10) end_seconds = `0${end_seconds}`;
        if (end_minutes < 10) end_minutes = `0${end_minutes}`;

        end_Time.innerHTML = `${end_hours || 0}:${end_minutes || "00"}:${end_seconds || "00"}`;

        if (audio.paused) {
            thumb_icone.classList.remove("fa-pause");
            thumb_icone.classList.add("fa-play");
        }

        // play_animation(!audio.paused);
    });
};

export const control_thumb_audio = () => {
    const input = document.querySelector("#thumb");
    const thump = document.querySelector("#seek");
    const seek_dot = document.querySelector("#seek_dot");
    input.addEventListener("change", () => {
        thump.style.width = input.value - 5 + "%";
        seek_dot.style.left = input.value - 5 + "%";
    });
}

export const control_thumb = () => {
    audio.addEventListener("timeupdate", () => {
        const thumb = document.querySelector("#thumb");
        const seek = document.querySelector("#seek");
        const seekDot = document.querySelector("#seek_dot");
        const progress = parseInt((audio.currentTime / audio.duration) * 100) || 0;
        thumb.value = progress;

        seek.style.width = `${thumb.value}%`;
        seekDot.style.left = `${thumb.value}%`;

        thumb.addEventListener("change", () => {
            audio.currentTime = thumb.value * audio.duration / 100;
        });

        audioEnded();
    });
}

// volume  handler
export const volumeHandler = () => {
    const volume = document.querySelector("#thumb-volume");
    const volumeIcon = document.querySelector(".volume-icone");
    const thumb = document.querySelector(".volume-controlar .thumb-ui");
    const seekDot = document.querySelector(".volume-controlar .thumb-cricle");

    const updateVolumeUI = () => {
        thumb.style.width = `${volume.value}%`;
        seekDot.style.left = `${volume.value}%`;
    };

    const updateVolumeIcon = () => {
        if (+volume.value === 0) {
            volumeIcon.classList.remove("fa-volume", "fa-volume-high");
            volumeIcon.classList.add("fa-volume-xmark");
        } else if (volume.value <= 50) {
            volumeIcon.classList.remove("fa-volume-xmark", "fa-volume-high");
            volumeIcon.classList.add("fa-volume-low", "fa-volume");
        } else if (volume.value > 50) {
            volumeIcon.classList.remove("fa-volume-xmark", "fa-volume-low");
            volumeIcon.classList.add("fa-volume-high", "fa-volume");
        }
    };

    const updateAudio = () => {
        audio.muted = (+volume.value === 0);
        audio.volume = volume.value / 100;
    };

    volume.addEventListener("input", () => {
        updateVolumeUI();
        updateVolumeIcon();
        updateAudio();
    });
}
    // Initialize UIHere's an improved version of the `volumeHandler` function: