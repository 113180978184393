import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { audio } from '../utilities';
import { useDispatch } from 'react-redux';
import { setReader } from '../../store/sliecs/getReader';
import SelectListBox from './SelectListBox/SelectListBox';



export default function SelectBox({ readers }) {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(readers[0]);
    useEffect(() => {
        dispatch(setReader(selected));
        audio.src = selected.src;
    }, [selected]);
    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                                <img src={selected['bg-sm']} alt="" className="h-5 w-5 flex-shrink-0 rounded-full me-4" />
                                <span className="ml-3 block truncate">{selected.name}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {readers.map((person, index) => (
                                    <SelectListBox key={index} person={person} index={index} />
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
