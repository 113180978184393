import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const fetchData = createAsyncThunk("quranSlice/QuranData", async () => {
    const response = await fetch("https://raw.githubusercontent.com/ProNabowy/Quran/main/quranKraim.json");
    const data = await response.json();
    return data;
})

const quranSlice = createSlice({
    initialState: [],
    name: "Quran Data",
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchData.fulfilled, (state, action) => {
            return state = action.payload
        });
    }
});

export default quranSlice.reducer;