import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchData } from "../../store/sliecs/quranSlice";
import { useEffect } from "react";

const ChaptersOfQuran = () => {
    const getData = useSelector(store => store.quranData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchData());
    }, []);
    const renderData = getData && getData.map((data, index) => {
        return (
            <div key={index} className='border-2 border-[#4a4a4a] col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-4 p-3 pt-9 pb-9 mb-3 text-center text-[25px] font-bold rounded-[10px]'>
                <Link to={`${data.id}?${data.name_arabic}`}><h1 className='cursor-pointer'>{data.name_arabic} ({data.name_complex})</h1></Link>
            </div>
        );
    });
    return (
        <div className="mt-9 mb-9">
            <div className="container">
                <div className='grid grid-cols-12 gap-3 p-2'>
                    {renderData}
                </div>
            </div>
        </div>
    )
}

export default ChaptersOfQuran;