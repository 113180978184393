import EffectIcons from "./Icone_Controlar/EffectIcons";
import Thumb from "./Thumb/Thumb";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Volume from "./Volume/Volume";

const ChapterControalr = () => {
    const prams = useParams().chapterId;
    const data = useSelector(store => store.quranData);
    const getCurrentChapter = data.filter(chapter => chapter.id == prams)[0];
    const getReader = useSelector(store => store.getReader);
    return (
        <div className="m_controaler fixed left-0 z-20 w-full bottom-0 p-[10px] bg-[#333638] ps-4" style={{direction: 'ltr'}}>
            <div className="container grid grid-cols-12 items-center">
                <div className=" hidden sm:block md:block lg:block sm:col-span-2 md:col-span-2 lg:col-span-1">
                    <h6 className="mb-1 text-white font-bold " style={{ "fontSize": "13px" }}>{getCurrentChapter.name_arabic}</h6>
                    <p className="text-white-50 font-bold" style={{ "fontSize": "10px" }}>{getReader.name}</p>
                </div>
                <div className="col-span-12 sm:col-span-1 md:col-span-1 lg:col-span-1 text-center">
                    <EffectIcons currentChapter={getCurrentChapter} />
                </div>
                <Thumb />
                <div className="col-span-12 sm:col-span-2 md:col-span-2 lg:col-span-1">
                    <Volume />
                </div>
            </div>
        </div>
    );
}


export default ChapterControalr;